"use client";
import { useEffect, useState } from "react";
import { Logo } from "./Logo";
import {
  ChevronDownIcon,
  CloseIcon,
  FacebookIcon,
  InstagramIcon,
  SpotifyIcon,
} from "./Icons";
import Link from "next/link";
import UserImgExp from "./user/UserImgExp";
import UserMenu from "./navbar/UserMenu";
import { useAuthContext } from "@/providers/AuthProvider";

const NavbarPrivate = () => {
  const { userData } = useAuthContext();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [openState, setOpenState] = useState(false);
  const genericHamburgerLine = `h-1 w-6 my-1 bg-white transition ease transform duration-300`;

  const handleUserMenu = () => {
    setOpenState(!openState);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (body && window.innerWidth < 768) {
      if (isOpenMenu) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }, [isOpenMenu]);

  return (
    <div className="bg-gradient-to-r from-orange to-bright-pink">
      {openState && (
        <div
          className={`fixed top-0 left-0 bg-transparent w-full h-full z-[50]`}
          onClick={() => setOpenState(false)}
        />
      )}
      <div className="container mx-auto flex items-center justify-between p-4 text-cream">
        <div className="flex items-center">
          <Link href="/">
            <Logo height="40px" className="fill-cream" />
          </Link>
        </div>
        <div className="flex">
          <button
            className="flex flex-col h-10 w-10 justify-center items-center group lg:hidden"
            onClick={() => setIsOpenMenu(!isOpenMenu)}
          >
            <div
              className={`${genericHamburgerLine} ${
                isOpenMenu
                  ? "rotate-45 translate-y-3 opacity-100 group-hover:opacity-100"
                  : "opacity-100 group-hover:opacity-100"
              }`}
            />
            <div
              className={`${genericHamburgerLine} ${
                isOpenMenu ? "opacity-0" : "opacity-100 group-hover:opacity-100"
              }`}
            />
            <div
              className={`${genericHamburgerLine} ${
                isOpenMenu
                  ? "-rotate-45 -translate-y-3 opacity-100 group-hover:opacity-100"
                  : "opacity-100 group-hover:opacity-100"
              }`}
            />
          </button>
          <div className="relative lg:hidden">
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={handleUserMenu}
            >
              <UserImgExp />
              <ChevronDownIcon height="16px" className="fill-cream" />
            </div>

            <UserMenu openState={openState} />
          </div>

          <div
            className={`absolute h-full w-full top-0 left-0 bg-bright-pink z-50 flex flex-col items-center justify-between py-12 ${
              isOpenMenu ? "scale-100" : "scale-0"
            }
            
            transition-transform duration-300 lg:scale-100 lg:relative lg:flex-row lg:py-0 lg:bg-transparent`}
          >
            <div className="absolute top-5 right-5 lg:hidden">
              <CloseIcon
                height="24px"
                className="fill-cream"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              />
            </div>
            <div className="lg:hidden">
              <Logo height="50px" className="fill-cream translate-x-3" />
            </div>
            <div className="flex flex-col items-center gap-3 text-xl font-light lg:flex-row lg:text-lg">
              <Link
                href="/app"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Inicio
              </Link>
              <Link
                href="/my-plan"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Mi Plan
              </Link>
              <Link
                href="/nutrition"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Nutrición
              </Link>
              <Link
                href="/my-progress"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Mi Progreso
              </Link>
              {userData?.role != 1 && userData?.role != 3 ? (
                <>
                  <Link
                    href="/app/classes"
                    className="text-white"
                    onClick={() => setIsOpenMenu(!isOpenMenu)}
                  >
                    Clases
                  </Link>
                  <Link
                    href="/app/programs"
                    className="text-white"
                    onClick={() => setIsOpenMenu(!isOpenMenu)}
                  >
                    Programas
                  </Link>
                </>
              ) : null}

              {userData?.role == 1 || userData?.role == 3 ? (
                <Link
                  href="/clase-gratis"
                  className="text-white"
                  onClick={() => setIsOpenMenu(!isOpenMenu)}
                >
                  Clase Gratis
                </Link>
              ) : null}

              <Link
                href="/live"
                className="inline-flex items-center gap-2 text-white text-md bg-orange py-0 px-2"
              >
                LIVE{" "}
                <div className="w-2 h-2 rounded-full bg-bright-pink animate-pulse" />
              </Link>
              <div className="relative hidden lg:block">
                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={handleUserMenu}
                >
                  <UserImgExp />
                  <ChevronDownIcon height="16px" className="fill-cream" />
                </div>

                <UserMenu openState={openState} />
              </div>
            </div>
            <div className="flex gap-3 lg:hidden">
              <Link
                href="https://open.spotify.com/playlist/5MITlbdpej2vXOqOgsxUOr?si=607bb01d119c43d1"
                target="_blank"
              >
                <SpotifyIcon className="fill-cream" height="24px" />
              </Link>
              <Link
                href="https://www.facebook.com/maluperezestudio"
                target="_blank"
              >
                <FacebookIcon className="fill-cream" height="24px" />
              </Link>
              <Link
                href="https://www.instagram.com/malu_perez/"
                target="_blank"
              >
                <InstagramIcon className="fill-cream" height="24px" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarPrivate;
